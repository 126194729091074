import Team from "./Views/Team.vue";
import TeamSubscriptionRedirect from "./Views/Subscribe.vue";
import TeamUsers from "./Views/TeamUsers.vue";
import OutputProfileEdit from "../Plan/Views/OutputProfileEdit.vue";
import TeamUserEdit from "./Views/TeamUserEdit.vue";
import TeamSettings from "./Views/TeamSettings.vue";

export default [
    {
        path: '/team',
        component: Team,
        meta: {
            middleware: ['auth', 'subscribed']
        },
        children: [
            {
                path: 'subscribe',
                component: TeamSubscriptionRedirect,
                meta: {
                    middleware: ['auth']
                },
            },
            {
                path: 'users',
                component: TeamUsers,
                meta: {
                    middleware: ['auth', 'subscribed']
                },
            },
            {
                path: 'users/create',
                component: TeamUserEdit,
                meta: {
                    middleware: ['auth', 'subscribed']
                },
            },
            {
                path: 'users/:id',
                component: TeamUserEdit,
                meta: {
                    middleware: ['auth', 'subscribed']
                },
            },
            {
                path: 'settings',
                component: TeamSettings,
                meta: {
                    middleware: ['auth', 'subscribed']
                },
            },
        ],
    },
];
