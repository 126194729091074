<template>
    <div class="view-team-subscribe text-center">
        <p>Your team is not yet subscribed.</p>
        <div v-if="isTeamOwner">
            <p>You'll be redirected to our billing portal in {{ countDownSeconds }} seconds.</p>
            <v-btn text="Redirect now" :href="billingUrl"/>
        </div>
    </div>
</template>

<script>
    import store from "../../Application/store.js";

    export default {
        data() {
            this.team = store.getters['team/team'];
            this.isTeamOwner = store.getters['auth/isTeamOwner'];
            this.billingUrl = '/billing/team/' + this.team.id;

            return {
                countDownSeconds: 10,
            };
        },
        mounted() {
            if (this.isTeamOwner) {
                this.startRedirectCountdown();
            }
        },
        methods: {
            startRedirectCountdown() {
                const self = this,
                    interval = setInterval(function() {
                        self.countDownSeconds--;
                        if (self.countDownSeconds === 0) {
                            clearInterval(interval);
                            window.location.href = self.billingUrl;
                        }
                    }, 1000);
            }
        }
    }
</script>
