import types from "../Shared/storeTypes.js";
import teamClient from "./teamClient.js";
import constants from "../Application/constants.js";

const
    state = {
        [types.TEAM_USERS]: [],
        [types.TEAM_USERS_TOTAL]: 0,
        [types.TEAM_USERS_PAGE]: 1,
        [types.PAGINATION_PER_PAGE]: constants.shared.PAGINATE_PER_PAGE_DEFAULT,
    },
    mutations = {
        [types.TEAM_USERS](state, data) {
            state[types.TEAM_USERS] = data.data;
            state[types.TEAM_USERS_TOTAL] = data.total;
            state[types.TEAM_USERS_PAGE] = data.current_page;
            state[types.PAGINATION_PER_PAGE] = data.per_page;
        },
    },
    getters = {
        /**
         * Return all users.
         * @param {object} state
         * @return {object}
         */
        users: state => state[types.TEAM_USERS],
        /**
         * Return a specific user from state.
         * @param {object} state
         * @return {object}
         */
        user: state => model => {
            const id = isNaN(model) ? model.id : +model;

            return state[types.TEAM_USERS].filter(user => user.id === id).shift();
        },
    },
    helpers = {
        /**
         * Verify if index page should be requested from server.
         * @param {{refresh: boolean}}parameters
         * @param {object} users
         * @returns {boolean}
         */
        shouldFetchAnew(parameters, users) {
            return parameters.refresh
                || parameters.sortBy
                || parameters.page !== state[types.TEAM_USERS_PAGE]
                || parameters.perPage !== state[types.PAGINATION_PER_PAGE]
                || !users.length;
        },
        /**
         * Cleanup data before storage on server.
         * @param {object} data
         * @returns {object}
         */
        sanitize(data) {
            data = Object.assign({}, data);

            data.firstname = data.firstname.trim();
            data.name = data.name.trim();
            data.email = data.email.trim();

            delete data.id;
            delete data.created_at;
            delete data.updated_at;

            return data;
        },
    },
    actions = {
        /**
         * Fetch the paginated resource.
         * @param {function} commit
         * @param {object} getters
         * @param {object} parameters
         * @return {Promise<array|null>}
         */
        index({commit, getters}, parameters = {}) {
            const users = getters.users;

            if (parameters.page) {
                parameters.perPage = parameters.perPage || getters.perPage;
            }

            if (!helpers.shouldFetchAnew(parameters, users)) {
                return Promise.resolve(users);
            }

            return teamClient
                .getUsers(parameters)
                .then(response => {
                    commit(types.TEAM_USERS, response.data);

                    return response.data.data;
                });
        },
        /**
         * Fetch a single resource.
         * @param {object} getters
         * @param {number} id
         * @return {Promise<object|null>}
         */
        get({getters}, id) {
            const user = getters.user(id);

            if (user) {
                return Promise.resolve(user);
            }

            return teamClient.getUser(id)
                .then(response => response.data.data);
        },
        /**
         * Update a resource and fetch the current page again.
         * @param {function} dispatch
         * @param {number} id
         * @param {object} attr
         * @return {Promise<object>}
         */
        async update({dispatch}, {id, attr}) {
            attr = helpers.sanitize(attr);

            const response = await teamClient.updateUser(id, attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Store a new resource and fetch the current page again.
         * @param {function} dispatch
         * @param {object} attr
         * @return {Promise<object>}
         */
        async store({dispatch}, attr) {
            attr = helpers.sanitize(attr);

            const response = await teamClient.storeUser(attr)
                .then(response => response.data.data);

            dispatch('index', {refresh: true});

            return response;
        },
        /**
         * Delete a resource and fetch the current page again.
         * @param {function} commit
         * @param {function} dispatch
         * @param {number} id
         * @return {Promise<null>}
         */
        destroy({dispatch}, id) {
            return teamClient
                .deleteUser(id)
                .then(() => dispatch('index', {refresh: true}));
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
