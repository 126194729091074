import store from "../store.js";
import {createRouter, createWebHistory} from "vue-router";
import authRoutes from "../../Auth/authRoutes.js";
import teamRoutes from "../../Team/teamRoutes.js";
import dashboardRoutes from "../../Dashboard/dashboardRoutes.js";
import PageNotFound from "../Views/PageNotFound.vue";
import middlewarePipeline from "./Middleware/";
import planRoutes from "../../Plan/planRoutes.js";
import viewPreloader from "./viewPreloader.js";
import sharedRoutes from "../../Shared/sharedRoutes.js";

/** Global app routes, the modules ones are imported from their respective folders. */
const appRoutes = [
    {
        path: '/',
        redirect: () => store.getters['auth/logged'] ? '/dashboard' : '/login',
    },
    {
        path: '/:pathMatch(.*)*',
        component: PageNotFound
    }
];

/** All Routes. */
const routes = [
    ...authRoutes,
    ...teamRoutes,
    ...dashboardRoutes,
    ...planRoutes,
    ...sharedRoutes,
    ...appRoutes,
];

/** Vue router object. */
const router = createRouter({
    history: createWebHistory(),
    routes
});


/** Run the middleware defined for each route. See the route file in each domain. */
router.beforeEach((to, from, next) => {
    const middleware = to.meta.middleware;

    if (!middleware) {
        return next();
    }

    middlewarePipeline({to, from, next, store}, middleware);
});

/** If a `preload` method is defined for the route's component, call it before resolving the component itself. */
// router.beforeResolve(viewPreloader(router, store));

export default router;
