import types from "../Shared/storeTypes.js";
import AuthService from "./authService.js";

const
    state = {
        [types.USER]: null,
        [types.LOGGED]: false,
        [types.IS_TEAM_OWNER]: false,
    },
    mutations = {
        [types.USER](state, user) {
            state[types.USER] = user;
            state[types.LOGGED] = !!user;
            state[types.IS_TEAM_OWNER] = user ? user.id === user.team.owner_id : false;
        },
    },
    getters = {
        /**
         * Return the authenticated.
         * @param {object} state
         * @return {object}
         */
        user: state => state[types.USER],
        /**
         * Is there someone authenticated?
         * @param {object} state
         * @return {boolean}
         */
        logged: state => state[types.LOGGED],
        /**
         * Does the authenticated user have the requested permission?
         * @param {object} state
         * @return {function}
         */
        userCan: state => permission => state[types.USER].permissions.indexOf(permission) > -1,
        /**
         * Is the current user the owner of their team?
         * @param {object} state
         * @return {function}
         */
        isTeamOwner: state => state[types.IS_TEAM_OWNER],
    },
    actions = {
        /**
         * Authenticate the user given the credentials.
         * @param {function} commit
         * @param {object} state
         * @param {function} dispatch
         * @param {string} username
         * @param {string} password
         * @return {Promise<object|null>}
         */
        login({commit, state, dispatch}, {username, password}) {
            if (state[types.USER]) {
                return Promise.resolve(state[types.USER]);
            }

            return AuthService
                .login(username, password)
                .then(() => dispatch('getAuthUser'));
        },
        /**
         * Get the user data for the currently authenticated user on the server.
         * @param {function} commit
         * @param {function} dispatch
         * @returns {*}
         */
        getAuthUser({commit, dispatch}) {
            return AuthService.getAuthUser()
                .then(user => {
                    dispatch('team/setCurrentTeam', user.team, {root: true});

                    commit(types.USER, user);

                    return user;
                });
        },
        /**
         * Remove the user from the store if logout was successful on the Laravel side.
         * @param {function} commit
         * @param {function} dispatch
         * @returns Promise<void>
         */
        logout({commit, dispatch}) {
            return AuthService
                .logout()
                .then(() => {
                    dispatch('team/unsetCurrentTeam', null, {root: true});

                    commit(types.USER, null);
                });
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
