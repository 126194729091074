<template>
    <div class="view-team-user-edit">
        <generic-view-title :title="(createMode ? 'Create a new' : 'Edit the') + ' team member'">
            <v-btn type="submit"
                   text="Save"
                   prepend-icon="spx:IconDeviceFloppy"
                   @click.prevent="submitForm"/>
        </generic-view-title>

        <v-form ref="form">
            <v-sheet class="pa-6 pb-0 mb-6" rounded>
                <h5 class="mb-3">General</h5>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.firstname"
                            type="text"
                            label="Firstname"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[$options.rules.required, errorMessages.firstname || true]"/>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.name"
                            type="text"
                            label="Name"
                            variant="outlined"
                            rounded="lg"
                            required
                            :rules="[$options.rules.required, errorMessages.name || true]"/>
                    </v-col>
                </v-row>
                <v-row class="pt-4">
                    <v-col cols="6">
                        <v-text-field
                            v-model="attr.email"
                            type="email"
                            :rules="[$options.rules.required, errorMessages.email || true]"
                            required
                            label="Email"
                            variant="outlined"
                            rounded="lg"/>
                    </v-col>
                    <v-col cols="6">
                        &nbsp;
                    </v-col>
                </v-row>
            </v-sheet>

            <v-row class="mb-1">
                <v-col cols="12" class="text-center">
                    <v-btn type="submit"
                           prepend-icon="spx:IconDeviceFloppy"
                           @click.prevent="submitForm">Save</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import validationMixin from "../../Shared/Mixins/validationMixin.js";
import formHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

const defaults = {
    firstname: '',
    name: '',
    slug: '',
    email: '',
};

export default {
    mixins: [validationMixin, formHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        return {
            attr: Object.assign({}, defaults),
        }
    },
    /**
     * Assign non-reactive data and fetch data from vuex/server.
     * @return void
     */
    created() {
        this.modelId = this.$route.params.id;

        this.createMode = !this.modelId;

        if (!this.createMode) {
            this.fetchModel(this.modelId);
        }
    },
    methods: {
        /**
         * Submit the form through the vuex store.
         * @returns void
         */
        async submitForm () {
            if (!await this.validateForm(this.$refs.form)) {
                return;
            }

            const http = this.createMode
                ? this.$store.dispatch('users/store', this.attr)
                : this.$store.dispatch('users/update', {id: this.modelId, attr: this.attr});

            http.then(data => this.attr = data)
                .then(data => {
                    this.submitMessage('User', this.createMode);
                    this.modelId = data.id;
                    this.createMode = false;
                })
                .catch(error => this.handleFormError(this.$refs.form, error));
        },
        /**
         * Fetch the model's attributes from the store/server.
         * @param {number} id
         * @returns void
         */
        fetchModel(id) {
            this.$store
                .dispatch('teamUsers/get', id)
                .then(user => (this.$data.attr = Object.assign(this.$data.attr, { ...user})));
        },
    },
}
</script>
