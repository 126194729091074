import axios from "axios";
import axiosConfig from "../Application/Services/axios.js";

const teamClient = axios.create({
    baseURL: '/api/v1/team/'
});

// Add the current team ID to all plan requests.
teamClient.interceptors.request.use(...axiosConfig.teamIdInterceptor);

export default {
    /**
     * Generate and test the uniqueness of a slug on the server.
     * @param {string} table
     * @param {string} slug
     * @returns {Promise<>}
     */
    generateSlug(table, slug) {
        return teamClient.get(`slug`, {
            params: {
                ctx: table,
                slug: slug
            }
        });
    },
    /**
     * Fetch all users.
     * @returns {Promise<array|null>}
     */
    getUsers(parameters) {
        let params = {
            page: parameters.page,
            per_page: parameters.perPage,
        }

        if (parameters.sortBy) {
            params.sort_by = parameters.sortBy;
            params.sort = parameters.sort;
        }

        return teamClient.get('users', {params});
    },
    /**
     * Fetch a specific user from the server.
     * @param {number} id
     * @returns {Promise<object|null>}
     */
    getUser: id=> teamClient.get(`users/${id}`),
    /**
     * Store the given data as an user on the server.
     * @param {object} attr
     * @returns {Promise<object>}
     */
    storeUser: attr => teamClient.post(`users`, attr),
    /**
     * Update the given user.
     * @param {number} id
     * @param {object} attr
     * @returns {Promise<object>}
     */
    updateUser: (id, attr)=> teamClient.put(`users/${id}`, attr),
    /**
     * Delete an user.
     * @param {number} id
     * @returns {Promise<>}
     */
    deleteUser: id => teamClient.delete(`users/${id}`),
};
