export default function auth({ to, next, store }) {
    const loginQuery = {name: 'Login', query: {redirect: to.fullPath}};

    if (store.getters['auth/user']) {
        return next();
    }

    store.dispatch('auth/getAuthUser')
        .then(() => next())
        .catch(() => next(loginQuery));
}
