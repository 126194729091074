import guest from "./guest.js";
import auth from "./auth.js";
import admin from "./admin.js";
import subscribed from "./subscribed.js";

const middlewares = {
    guest,
    auth,
    admin,
    subscribed
}

/** Run all defined middleware for a route recursively. */
export default function middlewarePipeline(context, middleware, index =  0) {
    const nextMiddlewareName = middleware[index],
        nextMiddleware = nextMiddlewareName ? middlewares[nextMiddlewareName] : null;

    if (!nextMiddlewareName) {
        return context.next;
    }

    const fn = () => {
        nextMiddleware({
            ...context,
            next: middlewarePipeline(context, middleware, index + 1),
        });
    };

    if (index === 0) fn();

    return fn;
}
