<template>
    <div class="view-team-users">
        <generic-view-title title="Team members">
            <div class="d-inline-block">
                <v-btn prepend-icon="spx:IconFolderPlus"
                       :disabled="createDisabled"
                       text="New"
                       @click="create"/>
                <v-tooltip v-if="createDisabled"
                           activator="parent"
                           location="bottom"
                           :text="createDisabledReason"/>
            </div>
        </generic-view-title>

        <v-data-table-server v-model:items-length="total"
                             :headers="headers"
                             :items="users"
                             :items-per-page="perPage"
                             :loading="loading"
                             :page="currentPage"
                             prev-icon="spx:pagePrev"
                             next-icon="spx:pageNext"
                             @update:options="seedTable">

            <template v-slot:item.name="{item}">
                <router-link :to="`/team/users/${item.id}`">{{ item.firstname }} {{ item.name }}</router-link>
            </template>


            <template v-slot:item.actions="{item}">
                <v-btn-secondary icon="spx:edit" @click.prevent="edit(item)" class="mr-2"/>
                <v-btn-secondary icon="spx:trash" @click.prevent="destroy(item)"/>
            </template>

        </v-data-table-server>
    </div>
</template>
<script>
import {mapState} from "vuex";
import GenericViewTitle from "../../Shared/Components/GenericViewTitle.vue";
import types from "../../Shared/storeTypes.js";
import paginationUrlMixin from "../../Shared/Mixins/paginationMixin.js";
import formatDateMixin from "../../Shared/Mixins/formatDateMixin.js";
import crudHelperMixin from "../../Shared/Mixins/crudHelperMixin.js";

export default {
    mixins: [paginationUrlMixin, formatDateMixin, crudHelperMixin],
    components: {
        GenericViewTitle,
    },
    data() {
        this.headers = [
            {key: 'name', title: 'Name'},
            {key: 'email', title: 'email'},
            {key: 'created_at', title: 'Created on', value: item => `${this.formatDate(item.created_at)}`},
            {key: 'actions', title: '', sortable: false, align: 'end'},
        ];

        return {
            createDisabled: false,
            createDisabledReason: '',
            team: this.$store.getters['team/team'],
        };
    },
    computed: {
        ...mapState('teamUsers', {
            users: types.TEAM_USERS,
            total: types.TEAM_USERS_TOTAL,
        }),
    },
    created() {
        this.allowAdCreation();
    },
    methods: {
        seedTable(options) {
            this.handlePagination('teamUsers/index', {
                perPage: options.itemsPerPage,
                page: options.page,
            });
        },
        create() {
            this.$router.push('/team/users/create');
        },
        edit(ad) {
            this.$router.push(`/team/users/${ad.id}`);
        },
        destroy(user) {
            this.$store.dispatch('teamUsers/destroy', +user.id)
                .then(() => this.deletedMessage('User'))
                .catch(e => this.handleHttpError(e));
        },
        async allowAdCreation() {
            if (!this.$store.getters['team/canCreateUsers']) {
                this.createDisabled = true;
                this.createDisabledReason = 'Upgrade your plan to create more users';
            }
        }
    }
}
</script>
