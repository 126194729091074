import {h} from "vue";
import {
    IconBell,
    IconCalendarCode,
    IconCalendarPlus,
    IconChevronDown,
    IconChevronLeft,
    IconChevronLeftPipe,
    IconChevronRight,
    IconChevronRightPipe,
    IconDeviceFloppy,
    IconDoor,
    IconEditCircle,
    IconEye,
    IconEyeExclamation,
    IconEyeOff,
    IconFlagCog,
    IconFolderCog,
    IconFolderPlus,
    IconInfoSquareRounded,
    IconLock,
    IconLogout,
    IconMenu2,
    IconSelector,
    IconSettings,
    IconSquare,
    IconSquareCheck,
    IconSquareRoundedChevronDown,
    IconSquareRoundedChevronLeft,
    IconSquareRoundedChevronRight,
    IconSquareRoundedChevronUp,
    IconTrash,
    IconUser,
    IconUsers,
    IconWallet,
    IconSquareX,

} from "@tabler/icons-vue";

const customSvgNameToComponent = {
    'mdi-arrow-down': IconChevronDown,
    'mdi-arrow-up': IconSquareRoundedChevronUp,
    'mdi-checkbox-blank-outline': IconSquare,
    'mdi-checkbox-marked': IconSquareCheck,
    'mdi-chevron-down': IconSquareRoundedChevronDown,
    'mdi-chevron-left': IconSquareRoundedChevronLeft,
    'mdi-chevron-right': IconSquareRoundedChevronRight,
    'mdi-page-prev': IconChevronLeft,
    'mdi-chevron-up': IconSquareRoundedChevronUp,
    'mdi-close-circle': IconSquareX,
    'mdi-menu-down': IconSelector,
    'mdi-page-first': IconChevronLeftPipe,
    'mdi-page-last': IconChevronRightPipe,
    pageNext: IconChevronRight,
    pagePrev: IconChevronLeft,

    IconBell,
    IconCalendarPlus,
    IconCalendarCode,
    IconDeviceFloppy,
    IconDoor,
    IconEye,
    IconEyeExclamation,
    IconEyeOff,
    IconFlagCog,
    IconFolderCog,
    IconFolderPlus,
    IconInfoSquareRounded,
    IconLogout,
    IconSettings,
    IconUser,
    IconUsers,
    IconWallet,

    edit: IconEditCircle,
    lock: IconLock,
    menu: IconMenu2,
    trash: IconTrash,
}
const tablerIcons = {
    component: props => h(
        props.tag,
        [
            h(customSvgNameToComponent[props.icon], {
                'stroke-width': 1.5,
                //class: 'v-icon__svg'
            })
        ]),
};

export { tablerIcons };
