import types from "../Shared/storeTypes.js";
import axiosConfig from "../Application/Services/axios.js";

const
    state = {
        [types.TEAM]: null,
        [types.TEAM_SUBSCRIBED]: false,
    },
    mutations = {
        [types.TEAM](state, team) {
            state[types.TEAM] = team;
            state[types.TEAM_SUBSCRIBED] = team ? !!team.subscribed : false;
        },
    },
    getters = {
        /**
         * Return the team of the authenticated user.
         * @param {object} state
         * @return {object}
         */
        team: state => state[types.TEAM],
        /**
         * Return the subscription state of the team of the authenticated user.
         * @param {object} state
         * @return {boolean}
         */
        subscribed: state => state[types.TEAM_SUBSCRIBED],
        /**
         * Check if the team can create more output profiles.
         * // TODO retrieve from Spark plan
         *             // TODO Retrieve count with initial team GET?
         * NOTE: this should be called when the profiles are loaded!
         * @param state
         * @param getters
         * @param rootState
         * @return boolean
         */
        canCreateOutputProfiles: (state, getters, rootState) => {

            const outputProfilesCount = rootState.outputProfiles.output_profiles.length;

            return outputProfilesCount < 5;
        },
        /**
         * Check if the team can create more users.
         * @param state
         * @param getters
         * @param rootState
         * @return boolean
         */
        canCreateUsers: (state, getters, rootState) => {
            // TODO retrieve from Spark plan
            // TODO Retrieve count with initial team GET?
            //const teamUsersCount = rootState.teamUsers.users.length;

            return true; //teamUsersCount < 10;
        },
    },
    actions = {
        setCurrentTeam({commit, dispatch}, team) {
            commit(types.TEAM, team);

            dispatch('notifications/setUnreadCount', team.unread_notification_count, {root: true});

            axiosConfig.setCurrentTeam(team.id);
        },
        unsetCurrentTeam({commit, dispatch}) {
            commit(types.TEAM, null);

            dispatch('notifications/setUnreadCount', 0, {root: true});

            axiosConfig.unsetCurrentTeam();
        }
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
