export default {
    // Shared domain
    PAGINATION_PER_PAGE: 'pagination_per_page',
    LANGUAGES: 'languages',
    NOTIFICATIONS: 'notifications',
    NOTIFICATIONS_TOTAL: 'notifications_total',
    NOTIFICATIONS_PAGE: 'notifications_page',
    NOTIFICATIONS_UNREAD_COUNT: 'notification_unread_count',

    // Auth domain: User types
    USER: 'user',
    LOGGED: 'logged',
    IS_TEAM_OWNER: 'isTeamOwner',

    // Plan domain: outputProfileStore.js
    OUTPUT_PROFILES: 'output_profiles',
    OUTPUT_PROFILES_TOTAL: 'output_profiles_total',
    OUTPUT_PROFILES_PAGE: 'output_profiles_page',

    // Plan domain: campaignStore.js
    CAMPAIGNS: 'campaigns',
    CAMPAIGNS_TOTAL: 'campaigns_total',
    CAMPAIGNS_PAGE: 'campaigns_page',

    // Plan domain: advertisementStore.js
    ADVERTISEMENTS: 'advertisements',
    ADVERTISEMENTS_TOTAL: 'advertisements_total',
    ADVERTISEMENTS_PAGE: 'advertisements_page',

    // Team domain
    TEAM: 'team',
    TEAM_SUBSCRIBED: 'subscribed',
    TEAM_USERS: 'team_users',
    TEAM_USERS_TOTAL: 'team_users_total',
    TEAM_USERS_PAGE: 'team_users_page',
};
